import styled from 'styled-components';

export const Wrapper = styled.div<{ $isAuth?: boolean | null }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ $isAuth }) => $isAuth && `min-width: 1330px;`}

  .add-single-phone-modal,
  .set-contacts-limit-modal {
    .bsg-modal-confirm-btns {
      margin-top: 0;
    }
  }
`;
