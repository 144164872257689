import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { GlobalStyle, lightTheme } from '@bsg-core/components';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { I18nextProvider } from 'react-i18next';

import Loader from 'shared/components/Loader';

import i18n from './shared/helpers/i18n';
import { router } from './router/router';
import { Wrapper } from './styles';

const root = document.getElementById('root');

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={lightTheme}>
      <Suspense fallback={<Loader isCovering />}>
        <Wrapper>
          <RouterProvider router={router} />
        </Wrapper>
        <GlobalStyle />
      </Suspense>
    </ThemeProvider>
  </I18nextProvider>,
  root,
);
