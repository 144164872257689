import styled from 'styled-components';

import { ISpinWrapperProps } from './types';

export const SSpinWrapper = styled.div<ISpinWrapperProps>`
  width: 100%;
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isCovering }) =>
    isCovering &&
    `
      top: 0;
      height: 100%;
      position: absolute;
      z-index: 1;
      border-radius: 2px 0 0 0;
      background: rgb(245 245 245 / 73%);
    `}
`;
