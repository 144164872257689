import { createBrowserRouter } from 'react-router-dom';
import { lazy } from 'react';

const SubscribePage = lazy(() => import('components/subscribe'));
const Unsubscribe = lazy(() => import('components/unsubscribe'));

const enum EUrlPages {
  Subscribe = '/subscribe/:idLink',
  Unsubscribe = '/unsubscribe/:idLink',
  Not_Found = '*',
}

export const router = createBrowserRouter([
  {
    path: EUrlPages.Subscribe,
    async lazy() {
      return { Component: SubscribePage };
    },
  },
  {
    path: EUrlPages.Unsubscribe,
    async lazy() {
      return { Component: Unsubscribe };
    },
  },
  {
    path: EUrlPages.Not_Found,
    Component: null,
  },
]);
