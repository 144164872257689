import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import cookie from 'react-cookies';
import { initReactI18next } from 'react-i18next';

import { SERVER_PREDEFINED } from 'config/app';

const locale = cookie.load('lang') || 'en';
const i18n = i18next.createInstance();

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .init({
    ns: ['landing-subcribtion'],
    fallbackLng: locale,
    defaultNS: 'landing-subcribtion',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: `${SERVER_PREDEFINED}/api/translate/{{ns}}/{{lng}}`,
    },
  });

i18n.on('initialized', () => {
  document.documentElement.setAttribute('lang', i18n.language);
});

i18n.on('languageChanged', () => {
  document.documentElement.setAttribute('lang', i18n.language);
});

export default i18n;
