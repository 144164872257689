import React, { FC } from 'react';
import { BSGSpin } from '@bsg-core/components';

import { ISpinWrapperProps } from './types';
import { SSpinWrapper } from './style';

const Loader: FC<ISpinWrapperProps> = ({
  height = '470px',
  isCovering,
  wrapper,
  ...props
}) => {
  return (
    <SSpinWrapper height={height} isCovering={isCovering} {...wrapper}>
      <BSGSpin {...props} />
    </SSpinWrapper>
  );
};

export default Loader;
